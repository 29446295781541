@import "../../_global.scss";

#pageDropDown {
    // background-color: #0D0D1C;
    // border-color: #0AA7FF;
    border-radius: 15px;
    background-color: $secondBgColor;
    border-color: $primaryColor;
    padding: 12px 24px;
}

thead {
    vertical-align: middle !important;
}

th {
    /* background: linear-gradient(180deg, #29A5DE 0%, #2B3C8E 100%) !important; */
    background-color: $primaryColor;
    color: white !important;
    align-items: center !important;
    padding: 15px !important;
}

td {
    background-color: transparent !important;
    color: black !important;
    border-style: none !important;

    /* text-align: center;  */
    vertical-align: middle;
    padding: 10px;
}

tr {
    /* background-color: #141414 !important; */
    // background-color: #0D0D1C !important;
    // border-color: #181829 !important;
    background-color: $inputBorderColor;
    border-color: $inputBgColor;
    border-width: 5px !important;
    border-style: solid !important;
    color: white !important;
}

.select {
    background-color: $mainBgColor;
    border-width: 1px;
    border-style: solid;
    border-color: $primaryColor;
    border-radius: 18px !important;
    color: white !important;
    font-size: 16px !important;
    padding-top: 13px !important;
    padding-bottom: 13px !important;
    line-height: 1.4 !important;
    padding: 10px;
    margin: 5px 0px 15px 0px;
    width: 100%;

    // arrow working
    background-image: linear-gradient(50deg, transparent 50%, white 60%), linear-gradient(125deg, white 40%, transparent 50%) !important;
    background-position: calc(100% - 20px) 20px, calc(100% - 15px) 20px, 100% 0 !important;
    background-size: 6px 10px, 6px 10px !important;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
}

.select:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
}
