$mainBgColor: #0036C9 !important;
$secondBgColor: #EFEFEF !important;

$inputBgColor: #FCFCFC !important;
$inputBorderColor: #E9E9E9 !important;
$darkBlue: #0036C9 !important;
$redColor: #EF2525 !important;
$yellowColor: #FAE100 !important;
$yellowColorWithAlpha: #fae100c1 !important;

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$primaryColor: #0036C9 !important;

$primaryColorWithAlpha: #0036C91a !important;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;

.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}

.black-text {
    color: black;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    // color: white !important;
    background-color: $primaryColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.secondary-btn {
    // color: white !important;
    background-color: $yellowColor;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.primary-border-btn {
    background-color: white !important;
    border: 1px solid $primaryColor;
}

.primary-text {
    color: $primaryColor;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}

// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
    yellowColor: $yellowColor;
}
