@import "../../../global";

.Details {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 20px;
    border-radius: 18px;
    padding-left: 6px;

    // background: #0D0D1C;
    background: $secondBgColor;
    display: flex;
    flex-direction: column;

    .backButton {
        width: fit-content;
        margin-top: -5px;
        margin-left: -5px;
        margin-bottom: 15px;

        // border: 1px solid #48556F;
        border-color: $primaryColor;
        border-style: solid;
        border-width: 1px;
        padding: 6px;
        font-size: 1rem;
        border-radius: 100%;
        display: inline-flex;
        color: white;
        cursor: pointer;
    }
}
