@import "../../../global";

.Navbar {
    padding: 0 15px;
    height: 60px;
    display: flex;
    align-items: center;

    // background-color: #0D0D1C;
    background-color: $primaryColor;

    // border-bottom: 1px solid lightgray;

    @media (max-width: 768px) {
        // padding: 12px 10px;
        // height: 30px;
    }

    .titleContainer {
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;

        span.toggleButton {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0 10px 0 0;
            font-size: 20px;

            // @media (min-width:991.68px) {
            //     display: none;
            // }

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        h3.title {
            font-size: 22px;
            font-weight: 700;
            margin: 0;

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }
    }

    .date {
        margin-right: auto;
        display: flex;
        font-size: 1.4rem;
        color: gray;
        justify-content: center;
        align-items: center;

        p {
            margin: 0 20px;
            font-size: 1rem;
        }
    }

    .search {
        display: flex;
        flex-direction: row;
        width: 20rem;
        height: auto;
        min-height: 30px;
        padding: 5px 10px;

        // background-color: #181829;
        background-color: $primaryColorWithAlpha;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        input {
            width: 100%;
            height: 100%;
            background-color: transparent;
            padding: 0 10px;
            color: white;
            font-size: 16px;
            border: none;
            outline: none;

            &::placeholder {
                color: $inputBorderColor;
            }
        }

        .searchButton {
            width: 35px;
            height: 30px;
            border-radius: 100%;

            // background-image: linear-gradient(to right, #B52929, #ED1B1B);
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            cursor: pointer;
            font-size: 1.3rem;
        }
    }

    .notificationButton {
        width: 35px;
        height: 80%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        font-size: 1.3rem;
        background-color: #181829;
        padding: 5px;
        margin: 5px 5px;
    }
}
